import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: string;
};




export type Authority = {
  __typename?: 'Authority';
  id: Scalars['String'];
  company?: Maybe<Company>;
  person?: Maybe<Person>;
  since?: Maybe<Scalars['AWSDateTime']>;
  role?: Maybe<Role>;
  representationType: RepresentationType;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['String'];
  companyId: Scalars['String'];
  company?: Maybe<Company>;
  bank: Scalars['String'];
  bic: Scalars['String'];
  iban: Scalars['String'];
  accountType: BankAccountType;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum BankAccountType {
  Loan = 'Loan',
  Business = 'Business',
  Management = 'Management',
  MaintenanceReserves = 'MaintenanceReserves'
}

export type BankMandate = {
  __typename?: 'BankMandate';
  id: Scalars['String'];
  person?: Maybe<Person>;
  bankAccount?: Maybe<BankAccount>;
  mandateType: BankMandateType;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum BankMandateType {
  A = 'A',
  B = 'B',
  E = 'E'
}

export type ChangeRecord = {
  __typename?: 'ChangeRecord';
  field: Scalars['String'];
  previousValue?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['String']>;
};

export type ChangeSet = {
  __typename?: 'ChangeSet';
  type: ChangeType;
  timestamp: Scalars['AWSDateTime'];
  records: Array<ChangeRecord>;
};

export enum ChangeType {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete'
}

export type Company = {
  __typename?: 'Company';
  id: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<CompanyStatus>;
  legalStatus: CompanyLegalStatus;
  lei: Scalars['String'];
  leiValidUntil?: Maybe<Scalars['AWSDateTime']>;
  purpose: Scalars['String'];
  legalType: CompanyLegalType;
  capital?: Maybe<Scalars['Float']>;
  taxationAuthority: Scalars['String'];
  taxId: Scalars['String'];
  FsakId?: Scalars['String'];
  vatNumber: Scalars['String'];
  registrationNumber: Scalars['String'];
  registrationCourt: Scalars['String'];
  creditorId: Scalars['String'];
  wkn: Scalars['String'];
  isin: Scalars['String'];
  bbankNumber: Scalars['String'];
  ihkNumber: Scalars['String'];
  paymentId: Scalars['String'];
  mangoPayUserId: Scalars['String'];
  formerName: Scalars['String'];
  usage?: Maybe<CompanyUsage>;
  address1: Scalars['String'];
  address2: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  country: Scalars['String'];
  mergedInto?: Maybe<Company>;
  registeredAt?: Maybe<Scalars['AWSDateTime']>;
  foundedAt?: Maybe<Scalars['AWSDateTime']>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum CompanyLegalStatus {
  InFoundation = 'InFoundation',
  FoundedOrActive = 'FoundedOrActive',
  InLiquidation = 'InLiquidation',
  Liquidated = 'Liquidated'
}

export enum CompanyLegalType {
  Gbr = 'GBR',
  Ug = 'UG',
  Gmbh = 'GMBH',
  Gmbhcokg = 'GMBHCOKG',
  Ag = 'AG',
  Sarl = 'SARL'
}

export enum CompanyStatus {
  NotReady = 'NotReady',
  Usable = 'Usable',
  InUse = 'InUse'
}

export enum CompanyUsage {
  Yield = 'Yield',
  Financing = 'Financing',
  OperatingCompany = 'OperatingCompany',
  Other = 'Other'
}

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['String'];
  name: Scalars['String'];
  plannedMeasure?: Maybe<PlannedMeasure>;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type CreateDocumentInput = {
  companyId: Scalars['String'];
  fileName: Scalars['String'];
  fileMimeType: Scalars['String'];
  documentType: DocumentType;
};

export type CreateDocumentOutput = Result & {
  __typename?: 'CreateDocumentOutput';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  signedUrl?: Maybe<Scalars['String']>;
  data?: Maybe<Document>;
};

export type DeleteOutput = Result & {
  __typename?: 'DeleteOutput';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['String'];
  company?: Maybe<Company>;
  fileName: Scalars['String'];
  fileMimeType: Scalars['String'];
  documentType: DocumentType;
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum DocumentType {
  DebitAgreement = 'DebitAgreement',
  ShareholderResolution = 'ShareholderResolution',
  Excerpt = 'Excerpt',
  Protocol = 'Protocol',
  Misc = 'Misc'
}

export type DownloadDocumentOutput = Result & {
  __typename?: 'DownloadDocumentOutput';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  signedUrl?: Maybe<Scalars['String']>;
  data?: Maybe<Document>;
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type ListAuthorityParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
};

export type ListBankAccountParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ListBankMandateParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  bankAccountId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
};

export type ListCompanyParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyStatus>;
  legalStatus?: Maybe<CompanyLegalStatus>;
  usage?: Maybe<CompanyUsage>;
  mergedIntoId?: Maybe<Scalars['String']>;
};

export type ListContactParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  plannedMeasureId?: Maybe<Scalars['String']>;
};

export type ListDocumentParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ListPersonParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ListPlannedMeasureParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['String']>;
};

export type ListPurposeTemplateParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  purposeTemplateId?: Maybe<Scalars['String']>;
};

export type ListShareholderParams = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  ownedId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertCompany: UpsertOutput;
  deleteCompany: DeleteOutput;
  upsertBankAccount: UpsertOutput;
  deleteBankAccount: DeleteOutput;
  upsertBankMandate: UpsertOutput;
  deleteBankMandate: DeleteOutput;
  upsertAuthority: UpsertOutput;
  deleteAuthority: DeleteOutput;
  upsertShareholder: UpsertOutput;
  deleteShareholder: DeleteOutput;
  upsertPerson: UpsertOutput;
  deletePerson: DeleteOutput;
  upsertPlannedMeasure: UpsertOutput;
  deletePlannedMeasure: DeleteOutput;
  upsertContact: UpsertOutput;
  deleteContact: DeleteOutput;
  createDocument: CreateDocumentOutput;
  deleteDocument: DeleteOutput;
  upsertPurposeTemplate: UpsertOutput;
  deletePurposeTemplate: DeleteOutput;
};


export type MutationUpsertCompanyArgs = {
  upsertCompanyInput: UpsertCompanyInput;
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['String'];
};


export type MutationUpsertBankAccountArgs = {
  upsertBankAccountInput: UpsertBankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  bankAccountId: Scalars['String'];
};


export type MutationUpsertBankMandateArgs = {
  upsertBankMandateInput: UpsertBankMandateInput;
};


export type MutationDeleteBankMandateArgs = {
  bankMandateId: Scalars['String'];
};


export type MutationUpsertAuthorityArgs = {
  upsertAuthorityInput: UpsertAuthorityInput;
};


export type MutationDeleteAuthorityArgs = {
  authorityId: Scalars['String'];
};


export type MutationUpsertShareholderArgs = {
  upsertShareholderInput: UpsertShareholderInput;
};


export type MutationDeleteShareholderArgs = {
  shareholderId: Scalars['String'];
};


export type MutationUpsertPersonArgs = {
  upsertPersonInput: UpsertPersonInput;
};


export type MutationDeletePersonArgs = {
  personId: Scalars['String'];
};


export type MutationUpsertPlannedMeasureArgs = {
  upsertPlannedMeasureInput: UpsertPlannedMeasureInput;
};


export type MutationDeletePlannedMeasureArgs = {
  plannedMeasureId: Scalars['String'];
};


export type MutationUpsertContactArgs = {
  upsertContactInput: UpsertContactInput;
};


export type MutationDeleteContactArgs = {
  contactId: Scalars['String'];
};


export type MutationCreateDocumentArgs = {
  createDocumentInput: CreateDocumentInput;
};


export type MutationDeleteDocumentArgs = {
  documentId: Scalars['String'];
};


export type MutationUpsertPurposeTemplateArgs = {
  upsertPurposeTemplateInput: UpsertPurposeTemplateInput;
};


export type MutationDeletePurposeTemplateArgs = {
  purposeTemplateId: Scalars['String'];
};

export type Person = {
  __typename?: 'Person';
  id: Scalars['String'];
  name: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type PlannedMeasure = {
  __typename?: 'PlannedMeasure';
  id: Scalars['String'];
  referenceId: Scalars['String'];
  referenceSource: PlannedMeasureSource;
  name: Scalars['String'];
  type: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
  company?: Maybe<Company>;
};

export enum PlannedMeasureSource {
  Immotool = 'IMMOTOOL',
  Magictool = 'MAGICTOOL'
}

export type PurposeTemplate = {
  __typename?: 'PurposeTemplate';
  id: Scalars['String'];
  text: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export type Query = {
  __typename?: 'Query';
  getCompanyById?: Maybe<Company>;
  countCompanies: Scalars['Int'];
  listCompanies: Array<Company>;
  listCompanyChanges: Array<ChangeSet>;
  searchCompanies: Array<Company>;
  getPersonById?: Maybe<Person>;
  countPersons: Scalars['Int'];
  listPersons: Array<Person>;
  searchPersons: Array<Person>;
  getPlannedMeasureById?: Maybe<PlannedMeasure>;
  countPlannedMeasures: Scalars['Int'];
  listPlannedMeasures: Array<PlannedMeasure>;
  searchPlannedMeasures: Array<PlannedMeasure>;
  getPurposeTemplateById?: Maybe<PurposeTemplate>;
  listAuthorities: Array<Authority>;
  listShareholders: Array<Shareholder>;
  listBankAccounts: Array<BankAccount>;
  listContacts: Array<Contact>;
  listBankMandates: Array<BankMandate>;
  listPurposeTemplates: Array<PurposeTemplate>;
  downloadDocument: DownloadDocumentOutput;
  listDocuments: Array<Document>;
};


export type QueryGetCompanyByIdArgs = {
  companyId: Scalars['String'];
};


export type QueryListCompaniesArgs = {
  listParams: ListCompanyParams;
};


export type QueryListCompanyChangesArgs = {
  companyId: Scalars['String'];
};


export type QuerySearchCompaniesArgs = {
  searchQuery: Scalars['String'];
};


export type QueryGetPersonByIdArgs = {
  personId: Scalars['String'];
};


export type QueryListPersonsArgs = {
  listParams: ListPersonParams;
};


export type QuerySearchPersonsArgs = {
  searchQuery: Scalars['String'];
};


export type QueryGetPlannedMeasureByIdArgs = {
  plannedMeasureId: Scalars['String'];
};


export type QueryListPlannedMeasuresArgs = {
  listParams: ListPlannedMeasureParams;
};


export type QuerySearchPlannedMeasuresArgs = {
  searchQuery: Scalars['String'];
};


export type QueryGetPurposeTemplateByIdArgs = {
  purposeTemplateId: Scalars['String'];
};


export type QueryListAuthoritiesArgs = {
  listParams: ListAuthorityParams;
};


export type QueryListShareholdersArgs = {
  listParams: ListShareholderParams;
};


export type QueryListBankAccountsArgs = {
  listParams: ListBankAccountParams;
};


export type QueryListContactsArgs = {
  listParams: ListContactParams;
};


export type QueryListBankMandatesArgs = {
  listParams: ListBankMandateParams;
};


export type QueryListPurposeTemplatesArgs = {
  listParams: ListPurposeTemplateParams;
};


export type QueryDownloadDocumentArgs = {
  documentId: Scalars['String'];
};


export type QueryListDocumentsArgs = {
  listParams: ListDocumentParams;
};

export enum RepresentationType {
  Solely = 'Solely',
  Joint = 'Joint'
}

/** Result object describing whether an action ran successfully */
export type Result = {
  /** Optional message describing what happened */
  message?: Maybe<Scalars['String']>;
  /** Optional errors array */
  errors?: Maybe<Array<Error>>;
};

export enum Role {
  PowerOfAttorney = 'PowerOfAttorney',
  LimitedPartner = 'LimitedPartner',
  Ceo = 'Ceo',
  Complementary = 'Complementary',
  Proxy = 'Proxy',
  AdministrativeBoard = 'AdministrativeBoard',
  Chairman = 'Chairman'
}

export type Shareholder = {
  __typename?: 'Shareholder';
  id: Scalars['String'];
  owned?: Maybe<Company>;
  ownedId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  person?: Maybe<Person>;
  personId?: Maybe<Scalars['String']>;
  shares: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum Sort {
  Asc = 'asc',
  Desc = 'desc'
}

export type UpsertAuthorityInput = {
  id?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  personId?: Maybe<Scalars['String']>;
  since?: Maybe<Scalars['AWSDateTime']>;
  role?: Maybe<Role>;
  representationType?: Maybe<RepresentationType>;
};

export type UpsertBankAccountInput = {
  id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  bank?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  accountType?: Maybe<BankAccountType>;
};

export type UpsertBankMandateInput = {
  id?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  bankAccountId?: Maybe<Scalars['String']>;
  mandateType?: Maybe<BankMandateType>;
};

export type UpsertCompanyInput = {
  id?: Maybe<Scalars['String']>;
  status?: Maybe<CompanyStatus>;
  legalStatus?: Maybe<CompanyLegalStatus>;
  name?: Maybe<Scalars['String']>;
  lei?: Maybe<Scalars['String']>;
  leiValidUntil?: Maybe<Scalars['AWSDateTime']>;
  purpose?: Maybe<Scalars['String']>;
  legalType?: Maybe<CompanyLegalType>;
  capital?: Maybe<Scalars['Float']>;
  taxationAuthority?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  FsakId?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  registrationNumber?: Maybe<Scalars['String']>;
  registrationCourt?: Maybe<Scalars['String']>;
  creditorId?: Maybe<Scalars['String']>;
  wkn?: Maybe<Scalars['String']>;
  isin?: Maybe<Scalars['String']>;
  bbankNumber?: Maybe<Scalars['String']>;
  ihkNumber?: Maybe<Scalars['String']>;
  paymentId?: Maybe<Scalars['String']>;
  mangoPayUserId?: Maybe<Scalars['String']>;
  formerName?: Maybe<Scalars['String']>;
  usage?: Maybe<CompanyUsage>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  mergedIntoId?: Maybe<Scalars['String']>;
  registeredAt?: Maybe<Scalars['AWSDateTime']>;
  foundedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type UpsertContactInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plannedMeasureId?: Maybe<Scalars['String']>;
};

/** Result object after an entity was created or updated */
export type UpsertOutput = Result & {
  __typename?: 'UpsertOutput';
  message?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  data?: Maybe<UpsertOutputData>;
};

export type UpsertOutputData = Authority | Shareholder | BankAccount | BankMandate | Company | Person | PlannedMeasure | Contact | Document | PurposeTemplate;

export type UpsertPersonInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UpsertPlannedMeasureInput = {
  id?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceSource?: Maybe<PlannedMeasureSource>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type UpsertPurposeTemplateInput = {
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type UpsertShareholderInput = {
  id?: Maybe<Scalars['String']>;
  ownedId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  personId?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['Float']>;
};

export type DashboardQueryVariables = Exact<{ [key: string]: never }>;


export type DashboardQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'countCompanies' | 'countPersons' | 'countPlannedMeasures'>
);

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'lei' | 'leiValidUntil' | 'purpose' | 'legalType' | 'capital' | 'taxationAuthority' | 'taxId' | 'FsakId' | 'vatNumber' | 'registrationNumber' | 'registrationCourt' | 'creditorId' | 'wkn' | 'isin' | 'bbankNumber' | 'ihkNumber' | 'paymentId' | 'mangoPayUserId' |'formerName' | 'usage' | 'address1' | 'address2' | 'city' | 'state' | 'zipCode' | 'country' | 'registeredAt' | 'foundedAt' | 'legalStatus' | 'status' | 'createdAt' | 'updatedAt'>
  & { mergedInto?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; }
);

export type GetCompanyByIdQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type GetCompanyByIdQuery = (
  { __typename?: 'Query' }
  & { getCompanyById?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )>; }
);

export type ListCompaniesQueryVariables = Exact<{
  listParams: ListCompanyParams;
}>;


export type ListCompaniesQuery = (
  { __typename?: 'Query' }
  & { listCompanies: Array<(
    { __typename?: 'Company' }
    & CompanyFragment
  )>; }
);

export type UpsertCompanyMutationVariables = Exact<{
  upsertCompanyInput: UpsertCompanyInput;
}>;


export type UpsertCompanyMutation = (
  { __typename?: 'Mutation' }
  & { upsertCompany: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | (
      { __typename?: 'Company' }
      & CompanyFragment
    ) | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteCompanyMutationVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type PersonFragment = (
  { __typename?: 'Person' }
  & Pick<Person, 'id' | 'name' | 'createdAt' | 'updatedAt'>
);

export type UpsertPersonMutationVariables = Exact<{
  upsertPersonInput: UpsertPersonInput;
}>;


export type UpsertPersonMutation = (
  { __typename?: 'Mutation' }
  & { upsertPerson: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | (
      { __typename?: 'Person' }
      & PersonFragment
    ) | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type GetPersonByIdQueryVariables = Exact<{
  personId: Scalars['String'];
}>;


export type GetPersonByIdQuery = (
  { __typename?: 'Query' }
  & { getPersonById?: Maybe<(
    { __typename?: 'Person' }
    & PersonFragment
  )>; }
);

export type ListPersonsQueryVariables = Exact<{
  listParams: ListPersonParams;
}>;


export type ListPersonsQuery = (
  { __typename?: 'Query' }
  & { listPersons: Array<(
    { __typename?: 'Person' }
    & PersonFragment
  )>; }
);

export type DeletePersonMutationVariables = Exact<{
  personId: Scalars['String'];
}>;


export type DeletePersonMutation = (
  { __typename?: 'Mutation' }
  & { deletePerson: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type ContactFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  & { plannedMeasure?: Maybe<(
    { __typename?: 'PlannedMeasure' }
    & Pick<PlannedMeasure, 'id' | 'name'>
  )>; }
);

export type ListContactsQueryVariables = Exact<{
  listParams: ListContactParams;
}>;


export type ListContactsQuery = (
  { __typename?: 'Query' }
  & { listContacts: Array<(
    { __typename?: 'Contact' }
    & ContactFragment
  )>; }
);

export type UpsertContactMutationVariables = Exact<{
  upsertContactInput: UpsertContactInput;
}>;


export type UpsertContactMutation = (
  { __typename?: 'Mutation' }
  & { upsertContact: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | (
      { __typename?: 'Contact' }
      & ContactFragment
    ) | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteContactMutationVariables = Exact<{
  contactId: Scalars['String'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type PlannedMeasureFragment = (
  { __typename?: 'PlannedMeasure' }
  & Pick<PlannedMeasure, 'id' | 'referenceId' | 'referenceSource' | 'name' | 'type' | 'createdAt' | 'updatedAt'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; }
);

export type GetPlannedMeasureByIdQueryVariables = Exact<{
  plannedMeasureId: Scalars['String'];
}>;


export type GetPlannedMeasureByIdQuery = (
  { __typename?: 'Query' }
  & { getPlannedMeasureById?: Maybe<(
    { __typename?: 'PlannedMeasure' }
    & PlannedMeasureFragment
  )>; }
);

export type ListPlannedMeasuresQueryVariables = Exact<{
  listParams: ListPlannedMeasureParams;
}>;


export type ListPlannedMeasuresQuery = (
  { __typename?: 'Query' }
  & { listPlannedMeasures: Array<(
    { __typename?: 'PlannedMeasure' }
    & PlannedMeasureFragment
  )>; }
);

export type UpsertPlannedMeasureMutationVariables = Exact<{
  upsertPlannedMeasureInput: UpsertPlannedMeasureInput;
}>;


export type UpsertPlannedMeasureMutation = (
  { __typename?: 'Mutation' }
  & { upsertPlannedMeasure: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | (
      { __typename?: 'PlannedMeasure' }
      & PlannedMeasureFragment
    ) | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeletePlannedMeasureMutationVariables = Exact<{
  plannedMeasureId: Scalars['String'];
}>;


export type DeletePlannedMeasureMutation = (
  { __typename?: 'Mutation' }
  & { deletePlannedMeasure: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type ListCompanyChangesQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type ListCompanyChangesQuery = (
  { __typename?: 'Query' }
  & { listCompanyChanges: Array<(
    { __typename?: 'ChangeSet' }
    & Pick<ChangeSet, 'type' | 'timestamp'>
    & { records: Array<(
      { __typename?: 'ChangeRecord' }
      & Pick<ChangeRecord, 'field' | 'previousValue' | 'currentValue'>
    )>; }
  )>; }
);

export type ListPersonsAndRolesQueryVariables = Exact<{ [key: string]: never }>;


export type ListPersonsAndRolesQuery = (
  { __typename?: 'Query' }
  & { listPersons: Array<(
    { __typename?: 'Person' }
    & PersonFragment
  )>; }
);

export type SearchQueryVariables = Exact<{
  searchQuery: Scalars['String'];
}>;


export type SearchQuery = (
  { __typename?: 'Query' }
  & { searchCompanies: Array<(
    { __typename: 'Company' }
    & CompanyFragment
  )>; searchPersons: Array<(
    { __typename: 'Person' }
    & PersonFragment
  )>; searchPlannedMeasures: Array<(
    { __typename: 'PlannedMeasure' }
    & PlannedMeasureFragment
  )>; }
);

export type DocumentFragment = (
  { __typename?: 'Document' }
  & Pick<Document, 'id' | 'fileName' | 'fileMimeType' | 'documentType' | 'createdAt' | 'updatedAt'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; }
);

export type CreateDocumentMutationVariables = Exact<{
  createDocumentInput: CreateDocumentInput;
}>;


export type CreateDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createDocument: (
    { __typename?: 'CreateDocumentOutput' }
    & Pick<CreateDocumentOutput, 'message' | 'signedUrl'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<(
      { __typename?: 'Document' }
      & DocumentFragment
    )>; }
  ); }
);

export type DownloadDocumentQueryVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type DownloadDocumentQuery = (
  { __typename?: 'Query' }
  & { downloadDocument: (
    { __typename?: 'DownloadDocumentOutput' }
    & Pick<DownloadDocumentOutput, 'message' | 'signedUrl'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<(
      { __typename?: 'Document' }
      & DocumentFragment
    )>; }
  ); }
);

export type ListDocumentsQueryVariables = Exact<{
  listParams: ListDocumentParams;
}>;


export type ListDocumentsQuery = (
  { __typename?: 'Query' }
  & { listDocuments: Array<(
    { __typename?: 'Document' }
    & DocumentFragment
  )>; }
);

export type DeleteDocumentMutationVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & { deleteDocument: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type AuthorityFragment = (
  { __typename?: 'Authority' }
  & Pick<Authority, 'id' | 'since' | 'role' | 'representationType'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; person?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'name'>
  )>; }
);

export type ListAuthoritiesQueryVariables = Exact<{
  listParams: ListAuthorityParams;
}>;


export type ListAuthoritiesQuery = (
  { __typename?: 'Query' }
  & { listAuthorities: Array<(
    { __typename?: 'Authority' }
    & AuthorityFragment
  )>; }
);

export type UpsertAuthorityMutationVariables = Exact<{
  upsertAuthorityInput: UpsertAuthorityInput;
}>;


export type UpsertAuthorityMutation = (
  { __typename?: 'Mutation' }
  & { upsertAuthority: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<(
      { __typename?: 'Authority' }
      & AuthorityFragment
    ) | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteAuthorityMutationVariables = Exact<{
  authorityId: Scalars['String'];
}>;


export type DeleteAuthorityMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuthority: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type ShareholderFragment = (
  { __typename?: 'Shareholder' }
  & Pick<Shareholder, 'id' | 'ownedId' | 'companyId' | 'personId' | 'shares' | 'createdAt' | 'updatedAt'>
  & { owned?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; person?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'name'>
  )>; }
);

export type ListShareholdersQueryVariables = Exact<{
  listParams: ListShareholderParams;
}>;


export type ListShareholdersQuery = (
  { __typename?: 'Query' }
  & { listShareholders: Array<(
    { __typename?: 'Shareholder' }
    & ShareholderFragment
  )>; }
);

export type UpsertShareholderMutationVariables = Exact<{
  upsertShareholderInput: UpsertShareholderInput;
}>;


export type UpsertShareholderMutation = (
  { __typename?: 'Mutation' }
  & { upsertShareholder: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | (
      { __typename?: 'Shareholder' }
      & ShareholderFragment
    ) | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteShareholderMutationVariables = Exact<{
  shareholderId: Scalars['String'];
}>;


export type DeleteShareholderMutation = (
  { __typename?: 'Mutation' }
  & { deleteShareholder: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type BankAccountFragment = (
  { __typename?: 'BankAccount' }
  & Pick<BankAccount, 'id' | 'bank' | 'bic' | 'iban' | 'accountType' | 'createdAt' | 'updatedAt'>
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'name'>
  )>; }
);

export type ListBankAccountsQueryVariables = Exact<{
  listParams: ListBankAccountParams;
}>;


export type ListBankAccountsQuery = (
  { __typename?: 'Query' }
  & { listBankAccounts: Array<(
    { __typename?: 'BankAccount' }
    & BankAccountFragment
  )>; }
);

export type UpsertBankAccountMutationVariables = Exact<{
  upsertBankAccountInput: UpsertBankAccountInput;
}>;


export type UpsertBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { upsertBankAccount: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | (
      { __typename?: 'BankAccount' }
      & BankAccountFragment
    ) | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteBankAccountMutationVariables = Exact<{
  bankAccountId: Scalars['String'];
}>;


export type DeleteBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteBankAccount: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type BankMandateFragment = (
  { __typename?: 'BankMandate' }
  & Pick<BankMandate, 'id' | 'mandateType' | 'createdAt' | 'updatedAt'>
  & { person?: Maybe<(
    { __typename?: 'Person' }
    & Pick<Person, 'id' | 'name' | 'createdAt' | 'updatedAt'>
  )>; bankAccount?: Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<BankAccount, 'id' | 'companyId' | 'accountType' | 'bank' | 'iban' | 'bic' | 'createdAt' | 'updatedAt'>
  )>; }
);

export type ListBankMandatesQueryVariables = Exact<{
  listParams: ListBankMandateParams;
}>;


export type ListBankMandatesQuery = (
  { __typename?: 'Query' }
  & { listBankMandates: Array<(
    { __typename?: 'BankMandate' }
    & BankMandateFragment
  )>; }
);

export type UpsertBankMandateMutationVariables = Exact<{
  upsertBankMandateInput: UpsertBankMandateInput;
}>;


export type UpsertBankMandateMutation = (
  { __typename?: 'Mutation' }
  & { upsertBankMandate: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | (
      { __typename?: 'BankMandate' }
      & BankMandateFragment
    ) | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | { __typename?: 'PurposeTemplate' }>; }
  ); }
);

export type DeleteBankMandateMutationVariables = Exact<{
  bankMandateId: Scalars['String'];
}>;


export type DeleteBankMandateMutation = (
  { __typename?: 'Mutation' }
  & { deleteBankMandate: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export type PurposeTemplateFragment = (
  { __typename?: 'PurposeTemplate' }
  & Pick<PurposeTemplate, 'id' | 'text' | 'createdAt' | 'updatedAt'>
);

export type GetPurposeTemplateByIdQueryVariables = Exact<{
  purposeTemplateId: Scalars['String'];
}>;


export type GetPurposeTemplateByIdQuery = (
  { __typename?: 'Query' }
  & { getPurposeTemplateById?: Maybe<(
    { __typename?: 'PurposeTemplate' }
    & PurposeTemplateFragment
  )>; }
);

export type ListPurposeTemplatesQueryVariables = Exact<{
  listParams: ListPurposeTemplateParams;
}>;


export type ListPurposeTemplatesQuery = (
  { __typename?: 'Query' }
  & { listPurposeTemplates: Array<(
    { __typename?: 'PurposeTemplate' }
    & PurposeTemplateFragment
  )>; }
);

export type UpsertPurposeTemplateMutationVariables = Exact<{
  upsertPurposeTemplateInput: UpsertPurposeTemplateInput;
}>;


export type UpsertPurposeTemplateMutation = (
  { __typename?: 'Mutation' }
  & { upsertPurposeTemplate: (
    { __typename?: 'UpsertOutput' }
    & Pick<UpsertOutput, 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; data?: Maybe<{ __typename?: 'Authority' } | { __typename?: 'Shareholder' } | { __typename?: 'BankAccount' } | { __typename?: 'BankMandate' } | { __typename?: 'Company' } | { __typename?: 'Person' } | { __typename?: 'PlannedMeasure' } | { __typename?: 'Contact' } | { __typename?: 'Document' } | (
      { __typename?: 'PurposeTemplate' }
      & PurposeTemplateFragment
    )>; }
  ); }
);

export type DeletePurposeTemplateMutationVariables = Exact<{
  purposeTemplateId: Scalars['String'];
}>;


export type DeletePurposeTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deletePurposeTemplate: (
    { __typename?: 'DeleteOutput' }
    & Pick<DeleteOutput, 'id' | 'message'>
    & { errors?: Maybe<Array<(
      { __typename?: 'Error' }
      & Pick<Error, 'message'>
    )>>; }
  ); }
);

export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  lei
  leiValidUntil
  purpose
  legalType
  capital
  taxationAuthority
  taxId
  FsakId
  vatNumber
  registrationNumber
  registrationCourt
  creditorId
  wkn
  isin
  bbankNumber
  ihkNumber
  paymentId
  mangoPayUserId
  formerName
  usage
  address1
  address2
  city
  state
  zipCode
  country
  registeredAt
  foundedAt
  legalStatus
  status
  mergedInto {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const PersonFragmentDoc = gql`
    fragment Person on Person {
  id
  name
  createdAt
  updatedAt
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  id
  name
  plannedMeasure {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const PlannedMeasureFragmentDoc = gql`
    fragment PlannedMeasure on PlannedMeasure {
  id
  referenceId
  referenceSource
  name
  type
  company {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  id
  company {
    id
    name
  }
  fileName
  fileMimeType
  documentType
  createdAt
  updatedAt
}
    `;
export const AuthorityFragmentDoc = gql`
    fragment Authority on Authority {
  id
  company {
    id
    name
  }
  person {
    id
    name
  }
  since
  role
  representationType
}
    `;
export const ShareholderFragmentDoc = gql`
    fragment Shareholder on Shareholder {
  id
  owned {
    id
    name
  }
  ownedId
  company {
    id
    name
  }
  companyId
  person {
    id
    name
  }
  personId
  shares
  createdAt
  updatedAt
}
    `;
export const BankAccountFragmentDoc = gql`
    fragment BankAccount on BankAccount {
  id
  company {
    id
    name
  }
  bank
  bic
  iban
  accountType
  createdAt
  updatedAt
}
    `;
export const BankMandateFragmentDoc = gql`
    fragment BankMandate on BankMandate {
  id
  person {
    id
    name
    createdAt
    updatedAt
  }
  bankAccount {
    id
    companyId
    accountType
    bank
    iban
    bic
    createdAt
    updatedAt
  }
  mandateType
  createdAt
  updatedAt
}
    `;
export const PurposeTemplateFragmentDoc = gql`
    fragment PurposeTemplate on PurposeTemplate {
  id
  text
  createdAt
  updatedAt
}
    `;
export const DashboardDocument = gql`
    query Dashboard {
  countCompanies
  countPersons
  countPlannedMeasures
}
    `;

/**
 * __useDashboardQuery__
 *
 * To run a query within a Vue component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDashboardQuery();
 */
export function useDashboardQuery(options: VueApolloComposable.UseQueryOptions<DashboardQuery, DashboardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DashboardQuery, DashboardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DashboardQuery, DashboardQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, {}, options);
}
export type DashboardQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DashboardQuery, DashboardQueryVariables>;
export const GetCompanyByIdDocument = gql`
    query GetCompanyById($companyId: String!) {
  getCompanyById(companyId: $companyId) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useGetCompanyByIdQuery__
 *
 * To run a query within a Vue component, call `useGetCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetCompanyByIdQuery({
 *   companyId: // value for 'companyId'
 * });
 */
export function useGetCompanyByIdQuery(variables: GetCompanyByIdQueryVariables | VueCompositionApi.Ref<GetCompanyByIdQueryVariables> | ReactiveFunction<GetCompanyByIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetCompanyByIdQuery, GetCompanyByIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>(GetCompanyByIdDocument, variables, options);
}
export type GetCompanyByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetCompanyByIdQuery, GetCompanyByIdQueryVariables>;
export const ListCompaniesDocument = gql`
    query ListCompanies($listParams: ListCompanyParams!) {
  listCompanies(listParams: $listParams) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a Vue component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListCompaniesQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListCompaniesQuery(variables: ListCompaniesQueryVariables | VueCompositionApi.Ref<ListCompaniesQueryVariables> | ReactiveFunction<ListCompaniesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListCompaniesQuery, ListCompaniesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListCompaniesQuery, ListCompaniesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListCompaniesQuery, ListCompaniesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(ListCompaniesDocument, variables, options);
}
export type ListCompaniesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListCompaniesQuery, ListCompaniesQueryVariables>;
export const UpsertCompanyDocument = gql`
    mutation UpsertCompany($upsertCompanyInput: UpsertCompanyInput!) {
  upsertCompany(upsertCompanyInput: $upsertCompanyInput) {
    message
    errors {
      message
    }
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useUpsertCompanyMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertCompanyMutation({
 *   variables: {
 *     upsertCompanyInput: // value for 'upsertCompanyInput'
 *   },
 * });
 */
export function useUpsertCompanyMutation(options: VueApolloComposable.UseMutationOptions<UpsertCompanyMutation, UpsertCompanyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertCompanyMutation, UpsertCompanyMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertCompanyMutation, UpsertCompanyMutationVariables>(UpsertCompanyDocument, options);
}
export type UpsertCompanyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertCompanyMutation, UpsertCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($companyId: String!) {
  deleteCompany(companyId: $companyId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteCompanyMutation({
 *   variables: {
 *     companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDeleteCompanyMutation(options: VueApolloComposable.UseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
}
export type DeleteCompanyMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const UpsertPersonDocument = gql`
    mutation UpsertPerson($upsertPersonInput: UpsertPersonInput!) {
  upsertPerson(upsertPersonInput: $upsertPersonInput) {
    message
    errors {
      message
    }
    data {
      ...Person
    }
  }
}
    ${PersonFragmentDoc}`;

/**
 * __useUpsertPersonMutation__
 *
 * To run a mutation, you first call `useUpsertPersonMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPersonMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertPersonMutation({
 *   variables: {
 *     upsertPersonInput: // value for 'upsertPersonInput'
 *   },
 * });
 */
export function useUpsertPersonMutation(options: VueApolloComposable.UseMutationOptions<UpsertPersonMutation, UpsertPersonMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertPersonMutation, UpsertPersonMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertPersonMutation, UpsertPersonMutationVariables>(UpsertPersonDocument, options);
}
export type UpsertPersonMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertPersonMutation, UpsertPersonMutationVariables>;
export const GetPersonByIdDocument = gql`
    query GetPersonById($personId: String!) {
  getPersonById(personId: $personId) {
    ...Person
  }
}
    ${PersonFragmentDoc}`;

/**
 * __useGetPersonByIdQuery__
 *
 * To run a query within a Vue component, call `useGetPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPersonByIdQuery({
 *   personId: // value for 'personId'
 * });
 */
export function useGetPersonByIdQuery(variables: GetPersonByIdQueryVariables | VueCompositionApi.Ref<GetPersonByIdQueryVariables> | ReactiveFunction<GetPersonByIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(GetPersonByIdDocument, variables, options);
}
export type GetPersonByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPersonByIdQuery, GetPersonByIdQueryVariables>;
export const ListPersonsDocument = gql`
    query ListPersons($listParams: ListPersonParams!) {
  listPersons(listParams: $listParams) {
    ...Person
  }
}
    ${PersonFragmentDoc}`;

/**
 * __useListPersonsQuery__
 *
 * To run a query within a Vue component, call `useListPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListPersonsQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListPersonsQuery(variables: ListPersonsQueryVariables | VueCompositionApi.Ref<ListPersonsQueryVariables> | ReactiveFunction<ListPersonsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListPersonsQuery, ListPersonsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListPersonsQuery, ListPersonsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListPersonsQuery, ListPersonsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListPersonsQuery, ListPersonsQueryVariables>(ListPersonsDocument, variables, options);
}
export type ListPersonsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListPersonsQuery, ListPersonsQueryVariables>;
export const DeletePersonDocument = gql`
    mutation DeletePerson($personId: String!) {
  deletePerson(personId: $personId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePersonMutation({
 *   variables: {
 *     personId: // value for 'personId'
 *   },
 * });
 */
export function useDeletePersonMutation(options: VueApolloComposable.UseMutationOptions<DeletePersonMutation, DeletePersonMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePersonMutation, DeletePersonMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePersonMutation, DeletePersonMutationVariables>(DeletePersonDocument, options);
}
export type DeletePersonMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePersonMutation, DeletePersonMutationVariables>;
export const ListContactsDocument = gql`
    query ListContacts($listParams: ListContactParams!) {
  listContacts(listParams: $listParams) {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useListContactsQuery__
 *
 * To run a query within a Vue component, call `useListContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListContactsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListContactsQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListContactsQuery(variables: ListContactsQueryVariables | VueCompositionApi.Ref<ListContactsQueryVariables> | ReactiveFunction<ListContactsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListContactsQuery, ListContactsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListContactsQuery, ListContactsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListContactsQuery, ListContactsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListContactsQuery, ListContactsQueryVariables>(ListContactsDocument, variables, options);
}
export type ListContactsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListContactsQuery, ListContactsQueryVariables>;
export const UpsertContactDocument = gql`
    mutation UpsertContact($upsertContactInput: UpsertContactInput!) {
  upsertContact(upsertContactInput: $upsertContactInput) {
    message
    errors {
      message
    }
    data {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useUpsertContactMutation__
 *
 * To run a mutation, you first call `useUpsertContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertContactMutation({
 *   variables: {
 *     upsertContactInput: // value for 'upsertContactInput'
 *   },
 * });
 */
export function useUpsertContactMutation(options: VueApolloComposable.UseMutationOptions<UpsertContactMutation, UpsertContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertContactMutation, UpsertContactMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertContactMutation, UpsertContactMutationVariables>(UpsertContactDocument, options);
}
export type UpsertContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertContactMutation, UpsertContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($contactId: String!) {
  deleteContact(contactId: $contactId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteContactMutation({
 *   variables: {
 *     contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteContactMutation(options: VueApolloComposable.UseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
}
export type DeleteContactMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteContactMutation, DeleteContactMutationVariables>;
export const GetPlannedMeasureByIdDocument = gql`
    query GetPlannedMeasureById($plannedMeasureId: String!) {
  getPlannedMeasureById(plannedMeasureId: $plannedMeasureId) {
    ...PlannedMeasure
  }
}
    ${PlannedMeasureFragmentDoc}`;

/**
 * __useGetPlannedMeasureByIdQuery__
 *
 * To run a query within a Vue component, call `useGetPlannedMeasureByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedMeasureByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPlannedMeasureByIdQuery({
 *   plannedMeasureId: // value for 'plannedMeasureId'
 * });
 */
export function useGetPlannedMeasureByIdQuery(variables: GetPlannedMeasureByIdQueryVariables | VueCompositionApi.Ref<GetPlannedMeasureByIdQueryVariables> | ReactiveFunction<GetPlannedMeasureByIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables>(GetPlannedMeasureByIdDocument, variables, options);
}
export type GetPlannedMeasureByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPlannedMeasureByIdQuery, GetPlannedMeasureByIdQueryVariables>;
export const ListPlannedMeasuresDocument = gql`
    query ListPlannedMeasures($listParams: ListPlannedMeasureParams!) {
  listPlannedMeasures(listParams: $listParams) {
    ...PlannedMeasure
  }
}
    ${PlannedMeasureFragmentDoc}`;

/**
 * __useListPlannedMeasuresQuery__
 *
 * To run a query within a Vue component, call `useListPlannedMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlannedMeasuresQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListPlannedMeasuresQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListPlannedMeasuresQuery(variables: ListPlannedMeasuresQueryVariables | VueCompositionApi.Ref<ListPlannedMeasuresQueryVariables> | ReactiveFunction<ListPlannedMeasuresQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables>(ListPlannedMeasuresDocument, variables, options);
}
export type ListPlannedMeasuresQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListPlannedMeasuresQuery, ListPlannedMeasuresQueryVariables>;
export const UpsertPlannedMeasureDocument = gql`
    mutation UpsertPlannedMeasure($upsertPlannedMeasureInput: UpsertPlannedMeasureInput!) {
  upsertPlannedMeasure(upsertPlannedMeasureInput: $upsertPlannedMeasureInput) {
    message
    errors {
      message
    }
    data {
      ...PlannedMeasure
    }
  }
}
    ${PlannedMeasureFragmentDoc}`;

/**
 * __useUpsertPlannedMeasureMutation__
 *
 * To run a mutation, you first call `useUpsertPlannedMeasureMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPlannedMeasureMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertPlannedMeasureMutation({
 *   variables: {
 *     upsertPlannedMeasureInput: // value for 'upsertPlannedMeasureInput'
 *   },
 * });
 */
export function useUpsertPlannedMeasureMutation(options: VueApolloComposable.UseMutationOptions<UpsertPlannedMeasureMutation, UpsertPlannedMeasureMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertPlannedMeasureMutation, UpsertPlannedMeasureMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertPlannedMeasureMutation, UpsertPlannedMeasureMutationVariables>(UpsertPlannedMeasureDocument, options);
}
export type UpsertPlannedMeasureMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertPlannedMeasureMutation, UpsertPlannedMeasureMutationVariables>;
export const DeletePlannedMeasureDocument = gql`
    mutation DeletePlannedMeasure($plannedMeasureId: String!) {
  deletePlannedMeasure(plannedMeasureId: $plannedMeasureId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeletePlannedMeasureMutation__
 *
 * To run a mutation, you first call `useDeletePlannedMeasureMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePlannedMeasureMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePlannedMeasureMutation({
 *   variables: {
 *     plannedMeasureId: // value for 'plannedMeasureId'
 *   },
 * });
 */
export function useDeletePlannedMeasureMutation(options: VueApolloComposable.UseMutationOptions<DeletePlannedMeasureMutation, DeletePlannedMeasureMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePlannedMeasureMutation, DeletePlannedMeasureMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePlannedMeasureMutation, DeletePlannedMeasureMutationVariables>(DeletePlannedMeasureDocument, options);
}
export type DeletePlannedMeasureMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePlannedMeasureMutation, DeletePlannedMeasureMutationVariables>;
export const ListCompanyChangesDocument = gql`
    query ListCompanyChanges($companyId: String!) {
  listCompanyChanges(companyId: $companyId) {
    type
    timestamp
    records {
      field
      previousValue
      currentValue
    }
  }
}
    `;

/**
 * __useListCompanyChangesQuery__
 *
 * To run a query within a Vue component, call `useListCompanyChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyChangesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListCompanyChangesQuery({
 *   companyId: // value for 'companyId'
 * });
 */
export function useListCompanyChangesQuery(variables: ListCompanyChangesQueryVariables | VueCompositionApi.Ref<ListCompanyChangesQueryVariables> | ReactiveFunction<ListCompanyChangesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListCompanyChangesQuery, ListCompanyChangesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListCompanyChangesQuery, ListCompanyChangesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListCompanyChangesQuery, ListCompanyChangesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListCompanyChangesQuery, ListCompanyChangesQueryVariables>(ListCompanyChangesDocument, variables, options);
}
export type ListCompanyChangesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListCompanyChangesQuery, ListCompanyChangesQueryVariables>;
export const ListPersonsAndRolesDocument = gql`
    query ListPersonsAndRoles {
  listPersons(listParams: {}) {
    ...Person
  }
}
    ${PersonFragmentDoc}`;

/**
 * __useListPersonsAndRolesQuery__
 *
 * To run a query within a Vue component, call `useListPersonsAndRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonsAndRolesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListPersonsAndRolesQuery();
 */
export function useListPersonsAndRolesQuery(options: VueApolloComposable.UseQueryOptions<ListPersonsAndRolesQuery, ListPersonsAndRolesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListPersonsAndRolesQuery, ListPersonsAndRolesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListPersonsAndRolesQuery, ListPersonsAndRolesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListPersonsAndRolesQuery, ListPersonsAndRolesQueryVariables>(ListPersonsAndRolesDocument, {}, options);
}
export type ListPersonsAndRolesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListPersonsAndRolesQuery, ListPersonsAndRolesQueryVariables>;
export const SearchDocument = gql`
    query Search($searchQuery: String!) {
  searchCompanies(searchQuery: $searchQuery) {
    __typename
    ...Company
  }
  searchPersons(searchQuery: $searchQuery) {
    __typename
    ...Person
  }
  searchPlannedMeasures(searchQuery: $searchQuery) {
    __typename
    ...PlannedMeasure
  }
}
    ${CompanyFragmentDoc}
${PersonFragmentDoc}
${PlannedMeasureFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a Vue component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchQuery({
 *   searchQuery: // value for 'searchQuery'
 * });
 */
export function useSearchQuery(variables: SearchQueryVariables | VueCompositionApi.Ref<SearchQueryVariables> | ReactiveFunction<SearchQueryVariables>, options: VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchQuery, SearchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, variables, options);
}
export type SearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SearchQuery, SearchQueryVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($createDocumentInput: CreateDocumentInput!) {
  createDocument(createDocumentInput: $createDocumentInput) {
    message
    errors {
      message
    }
    signedUrl
    data {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDocumentMutation({
 *   variables: {
 *     createDocumentInput: // value for 'createDocumentInput'
 *   },
 * });
 */
export function useCreateDocumentMutation(options: VueApolloComposable.UseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>>) {
  return VueApolloComposable.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
}
export type CreateDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const DownloadDocumentDocument = gql`
    query DownloadDocument($documentId: String!) {
  downloadDocument(documentId: $documentId) {
    message
    errors {
      message
    }
    signedUrl
    data {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useDownloadDocumentQuery__
 *
 * To run a query within a Vue component, call `useDownloadDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadDocumentQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDownloadDocumentQuery({
 *   documentId: // value for 'documentId'
 * });
 */
export function useDownloadDocumentQuery(variables: DownloadDocumentQueryVariables | VueCompositionApi.Ref<DownloadDocumentQueryVariables> | ReactiveFunction<DownloadDocumentQueryVariables>, options: VueApolloComposable.UseQueryOptions<DownloadDocumentQuery, DownloadDocumentQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DownloadDocumentQuery, DownloadDocumentQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DownloadDocumentQuery, DownloadDocumentQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DownloadDocumentQuery, DownloadDocumentQueryVariables>(DownloadDocumentDocument, variables, options);
}
export type DownloadDocumentQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DownloadDocumentQuery, DownloadDocumentQueryVariables>;
export const ListDocumentsDocument = gql`
    query ListDocuments($listParams: ListDocumentParams!) {
  listDocuments(listParams: $listParams) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useListDocumentsQuery__
 *
 * To run a query within a Vue component, call `useListDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDocumentsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListDocumentsQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListDocumentsQuery(variables: ListDocumentsQueryVariables | VueCompositionApi.Ref<ListDocumentsQueryVariables> | ReactiveFunction<ListDocumentsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListDocumentsQuery, ListDocumentsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListDocumentsQuery, ListDocumentsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListDocumentsQuery, ListDocumentsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListDocumentsQuery, ListDocumentsQueryVariables>(ListDocumentsDocument, variables, options);
}
export type ListDocumentsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListDocumentsQuery, ListDocumentsQueryVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($documentId: String!) {
  deleteDocument(documentId: $documentId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDocumentMutation({
 *   variables: {
 *     documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDeleteDocumentMutation(options: VueApolloComposable.UseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const ListAuthoritiesDocument = gql`
    query ListAuthorities($listParams: ListAuthorityParams!) {
  listAuthorities(listParams: $listParams) {
    ...Authority
  }
}
    ${AuthorityFragmentDoc}`;

/**
 * __useListAuthoritiesQuery__
 *
 * To run a query within a Vue component, call `useListAuthoritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAuthoritiesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListAuthoritiesQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListAuthoritiesQuery(variables: ListAuthoritiesQueryVariables | VueCompositionApi.Ref<ListAuthoritiesQueryVariables> | ReactiveFunction<ListAuthoritiesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListAuthoritiesQuery, ListAuthoritiesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListAuthoritiesQuery, ListAuthoritiesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListAuthoritiesQuery, ListAuthoritiesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListAuthoritiesQuery, ListAuthoritiesQueryVariables>(ListAuthoritiesDocument, variables, options);
}
export type ListAuthoritiesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListAuthoritiesQuery, ListAuthoritiesQueryVariables>;
export const UpsertAuthorityDocument = gql`
    mutation UpsertAuthority($upsertAuthorityInput: UpsertAuthorityInput!) {
  upsertAuthority(upsertAuthorityInput: $upsertAuthorityInput) {
    message
    errors {
      message
    }
    data {
      ...Authority
    }
  }
}
    ${AuthorityFragmentDoc}`;

/**
 * __useUpsertAuthorityMutation__
 *
 * To run a mutation, you first call `useUpsertAuthorityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAuthorityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertAuthorityMutation({
 *   variables: {
 *     upsertAuthorityInput: // value for 'upsertAuthorityInput'
 *   },
 * });
 */
export function useUpsertAuthorityMutation(options: VueApolloComposable.UseMutationOptions<UpsertAuthorityMutation, UpsertAuthorityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertAuthorityMutation, UpsertAuthorityMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertAuthorityMutation, UpsertAuthorityMutationVariables>(UpsertAuthorityDocument, options);
}
export type UpsertAuthorityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertAuthorityMutation, UpsertAuthorityMutationVariables>;
export const DeleteAuthorityDocument = gql`
    mutation DeleteAuthority($authorityId: String!) {
  deleteAuthority(authorityId: $authorityId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteAuthorityMutation__
 *
 * To run a mutation, you first call `useDeleteAuthorityMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuthorityMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAuthorityMutation({
 *   variables: {
 *     authorityId: // value for 'authorityId'
 *   },
 * });
 */
export function useDeleteAuthorityMutation(options: VueApolloComposable.UseMutationOptions<DeleteAuthorityMutation, DeleteAuthorityMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteAuthorityMutation, DeleteAuthorityMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteAuthorityMutation, DeleteAuthorityMutationVariables>(DeleteAuthorityDocument, options);
}
export type DeleteAuthorityMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteAuthorityMutation, DeleteAuthorityMutationVariables>;
export const ListShareholdersDocument = gql`
    query ListShareholders($listParams: ListShareholderParams!) {
  listShareholders(listParams: $listParams) {
    ...Shareholder
  }
}
    ${ShareholderFragmentDoc}`;

/**
 * __useListShareholdersQuery__
 *
 * To run a query within a Vue component, call `useListShareholdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShareholdersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListShareholdersQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListShareholdersQuery(variables: ListShareholdersQueryVariables | VueCompositionApi.Ref<ListShareholdersQueryVariables> | ReactiveFunction<ListShareholdersQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListShareholdersQuery, ListShareholdersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListShareholdersQuery, ListShareholdersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListShareholdersQuery, ListShareholdersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListShareholdersQuery, ListShareholdersQueryVariables>(ListShareholdersDocument, variables, options);
}
export type ListShareholdersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListShareholdersQuery, ListShareholdersQueryVariables>;
export const UpsertShareholderDocument = gql`
    mutation UpsertShareholder($upsertShareholderInput: UpsertShareholderInput!) {
  upsertShareholder(upsertShareholderInput: $upsertShareholderInput) {
    message
    errors {
      message
    }
    data {
      ...Shareholder
    }
  }
}
    ${ShareholderFragmentDoc}`;

/**
 * __useUpsertShareholderMutation__
 *
 * To run a mutation, you first call `useUpsertShareholderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertShareholderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertShareholderMutation({
 *   variables: {
 *     upsertShareholderInput: // value for 'upsertShareholderInput'
 *   },
 * });
 */
export function useUpsertShareholderMutation(options: VueApolloComposable.UseMutationOptions<UpsertShareholderMutation, UpsertShareholderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertShareholderMutation, UpsertShareholderMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertShareholderMutation, UpsertShareholderMutationVariables>(UpsertShareholderDocument, options);
}
export type UpsertShareholderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertShareholderMutation, UpsertShareholderMutationVariables>;
export const DeleteShareholderDocument = gql`
    mutation DeleteShareholder($shareholderId: String!) {
  deleteShareholder(shareholderId: $shareholderId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteShareholderMutation__
 *
 * To run a mutation, you first call `useDeleteShareholderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShareholderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteShareholderMutation({
 *   variables: {
 *     shareholderId: // value for 'shareholderId'
 *   },
 * });
 */
export function useDeleteShareholderMutation(options: VueApolloComposable.UseMutationOptions<DeleteShareholderMutation, DeleteShareholderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteShareholderMutation, DeleteShareholderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteShareholderMutation, DeleteShareholderMutationVariables>(DeleteShareholderDocument, options);
}
export type DeleteShareholderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteShareholderMutation, DeleteShareholderMutationVariables>;
export const ListBankAccountsDocument = gql`
    query ListBankAccounts($listParams: ListBankAccountParams!) {
  listBankAccounts(listParams: $listParams) {
    ...BankAccount
  }
}
    ${BankAccountFragmentDoc}`;

/**
 * __useListBankAccountsQuery__
 *
 * To run a query within a Vue component, call `useListBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBankAccountsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListBankAccountsQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListBankAccountsQuery(variables: ListBankAccountsQueryVariables | VueCompositionApi.Ref<ListBankAccountsQueryVariables> | ReactiveFunction<ListBankAccountsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListBankAccountsQuery, ListBankAccountsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListBankAccountsQuery, ListBankAccountsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListBankAccountsQuery, ListBankAccountsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListBankAccountsQuery, ListBankAccountsQueryVariables>(ListBankAccountsDocument, variables, options);
}
export type ListBankAccountsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListBankAccountsQuery, ListBankAccountsQueryVariables>;
export const UpsertBankAccountDocument = gql`
    mutation UpsertBankAccount($upsertBankAccountInput: UpsertBankAccountInput!) {
  upsertBankAccount(upsertBankAccountInput: $upsertBankAccountInput) {
    message
    errors {
      message
    }
    data {
      ...BankAccount
    }
  }
}
    ${BankAccountFragmentDoc}`;

/**
 * __useUpsertBankAccountMutation__
 *
 * To run a mutation, you first call `useUpsertBankAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBankAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertBankAccountMutation({
 *   variables: {
 *     upsertBankAccountInput: // value for 'upsertBankAccountInput'
 *   },
 * });
 */
export function useUpsertBankAccountMutation(options: VueApolloComposable.UseMutationOptions<UpsertBankAccountMutation, UpsertBankAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertBankAccountMutation, UpsertBankAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertBankAccountMutation, UpsertBankAccountMutationVariables>(UpsertBankAccountDocument, options);
}
export type UpsertBankAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertBankAccountMutation, UpsertBankAccountMutationVariables>;
export const DeleteBankAccountDocument = gql`
    mutation DeleteBankAccount($bankAccountId: String!) {
  deleteBankAccount(bankAccountId: $bankAccountId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteBankAccountMutation__
 *
 * To run a mutation, you first call `useDeleteBankAccountMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankAccountMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteBankAccountMutation({
 *   variables: {
 *     bankAccountId: // value for 'bankAccountId'
 *   },
 * });
 */
export function useDeleteBankAccountMutation(options: VueApolloComposable.UseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>(DeleteBankAccountDocument, options);
}
export type DeleteBankAccountMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteBankAccountMutation, DeleteBankAccountMutationVariables>;
export const ListBankMandatesDocument = gql`
    query ListBankMandates($listParams: ListBankMandateParams!) {
  listBankMandates(listParams: $listParams) {
    ...BankMandate
  }
}
    ${BankMandateFragmentDoc}`;

/**
 * __useListBankMandatesQuery__
 *
 * To run a query within a Vue component, call `useListBankMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBankMandatesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListBankMandatesQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListBankMandatesQuery(variables: ListBankMandatesQueryVariables | VueCompositionApi.Ref<ListBankMandatesQueryVariables> | ReactiveFunction<ListBankMandatesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListBankMandatesQuery, ListBankMandatesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListBankMandatesQuery, ListBankMandatesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListBankMandatesQuery, ListBankMandatesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListBankMandatesQuery, ListBankMandatesQueryVariables>(ListBankMandatesDocument, variables, options);
}
export type ListBankMandatesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListBankMandatesQuery, ListBankMandatesQueryVariables>;
export const UpsertBankMandateDocument = gql`
    mutation UpsertBankMandate($upsertBankMandateInput: UpsertBankMandateInput!) {
  upsertBankMandate(upsertBankMandateInput: $upsertBankMandateInput) {
    message
    errors {
      message
    }
    data {
      ...BankMandate
    }
  }
}
    ${BankMandateFragmentDoc}`;

/**
 * __useUpsertBankMandateMutation__
 *
 * To run a mutation, you first call `useUpsertBankMandateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBankMandateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertBankMandateMutation({
 *   variables: {
 *     upsertBankMandateInput: // value for 'upsertBankMandateInput'
 *   },
 * });
 */
export function useUpsertBankMandateMutation(options: VueApolloComposable.UseMutationOptions<UpsertBankMandateMutation, UpsertBankMandateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertBankMandateMutation, UpsertBankMandateMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertBankMandateMutation, UpsertBankMandateMutationVariables>(UpsertBankMandateDocument, options);
}
export type UpsertBankMandateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertBankMandateMutation, UpsertBankMandateMutationVariables>;
export const DeleteBankMandateDocument = gql`
    mutation DeleteBankMandate($bankMandateId: String!) {
  deleteBankMandate(bankMandateId: $bankMandateId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeleteBankMandateMutation__
 *
 * To run a mutation, you first call `useDeleteBankMandateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBankMandateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteBankMandateMutation({
 *   variables: {
 *     bankMandateId: // value for 'bankMandateId'
 *   },
 * });
 */
export function useDeleteBankMandateMutation(options: VueApolloComposable.UseMutationOptions<DeleteBankMandateMutation, DeleteBankMandateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteBankMandateMutation, DeleteBankMandateMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteBankMandateMutation, DeleteBankMandateMutationVariables>(DeleteBankMandateDocument, options);
}
export type DeleteBankMandateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteBankMandateMutation, DeleteBankMandateMutationVariables>;
export const GetPurposeTemplateByIdDocument = gql`
    query GetPurposeTemplateById($purposeTemplateId: String!) {
  getPurposeTemplateById(purposeTemplateId: $purposeTemplateId) {
    ...PurposeTemplate
  }
}
    ${PurposeTemplateFragmentDoc}`;

/**
 * __useGetPurposeTemplateByIdQuery__
 *
 * To run a query within a Vue component, call `useGetPurposeTemplateByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurposeTemplateByIdQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGetPurposeTemplateByIdQuery({
 *   purposeTemplateId: // value for 'purposeTemplateId'
 * });
 */
export function useGetPurposeTemplateByIdQuery(variables: GetPurposeTemplateByIdQueryVariables | VueCompositionApi.Ref<GetPurposeTemplateByIdQueryVariables> | ReactiveFunction<GetPurposeTemplateByIdQueryVariables>, options: VueApolloComposable.UseQueryOptions<GetPurposeTemplateByIdQuery, GetPurposeTemplateByIdQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GetPurposeTemplateByIdQuery, GetPurposeTemplateByIdQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GetPurposeTemplateByIdQuery, GetPurposeTemplateByIdQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GetPurposeTemplateByIdQuery, GetPurposeTemplateByIdQueryVariables>(GetPurposeTemplateByIdDocument, variables, options);
}
export type GetPurposeTemplateByIdQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GetPurposeTemplateByIdQuery, GetPurposeTemplateByIdQueryVariables>;
export const ListPurposeTemplatesDocument = gql`
    query ListPurposeTemplates($listParams: ListPurposeTemplateParams!) {
  listPurposeTemplates(listParams: $listParams) {
    ...PurposeTemplate
  }
}
    ${PurposeTemplateFragmentDoc}`;

/**
 * __useListPurposeTemplatesQuery__
 *
 * To run a query within a Vue component, call `useListPurposeTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPurposeTemplatesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useListPurposeTemplatesQuery({
 *   listParams: // value for 'listParams'
 * });
 */
export function useListPurposeTemplatesQuery(variables: ListPurposeTemplatesQueryVariables | VueCompositionApi.Ref<ListPurposeTemplatesQueryVariables> | ReactiveFunction<ListPurposeTemplatesQueryVariables>, options: VueApolloComposable.UseQueryOptions<ListPurposeTemplatesQuery, ListPurposeTemplatesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ListPurposeTemplatesQuery, ListPurposeTemplatesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ListPurposeTemplatesQuery, ListPurposeTemplatesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ListPurposeTemplatesQuery, ListPurposeTemplatesQueryVariables>(ListPurposeTemplatesDocument, variables, options);
}
export type ListPurposeTemplatesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ListPurposeTemplatesQuery, ListPurposeTemplatesQueryVariables>;
export const UpsertPurposeTemplateDocument = gql`
    mutation UpsertPurposeTemplate($upsertPurposeTemplateInput: UpsertPurposeTemplateInput!) {
  upsertPurposeTemplate(upsertPurposeTemplateInput: $upsertPurposeTemplateInput) {
    message
    errors {
      message
    }
    data {
      ...PurposeTemplate
    }
  }
}
    ${PurposeTemplateFragmentDoc}`;

/**
 * __useUpsertPurposeTemplateMutation__
 *
 * To run a mutation, you first call `useUpsertPurposeTemplateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPurposeTemplateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpsertPurposeTemplateMutation({
 *   variables: {
 *     upsertPurposeTemplateInput: // value for 'upsertPurposeTemplateInput'
 *   },
 * });
 */
export function useUpsertPurposeTemplateMutation(options: VueApolloComposable.UseMutationOptions<UpsertPurposeTemplateMutation, UpsertPurposeTemplateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpsertPurposeTemplateMutation, UpsertPurposeTemplateMutationVariables>>) {
  return VueApolloComposable.useMutation<UpsertPurposeTemplateMutation, UpsertPurposeTemplateMutationVariables>(UpsertPurposeTemplateDocument, options);
}
export type UpsertPurposeTemplateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpsertPurposeTemplateMutation, UpsertPurposeTemplateMutationVariables>;
export const DeletePurposeTemplateDocument = gql`
    mutation DeletePurposeTemplate($purposeTemplateId: String!) {
  deletePurposeTemplate(purposeTemplateId: $purposeTemplateId) {
    id
    message
    errors {
      message
    }
  }
}
    `;

/**
 * __useDeletePurposeTemplateMutation__
 *
 * To run a mutation, you first call `useDeletePurposeTemplateMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurposeTemplateMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePurposeTemplateMutation({
 *   variables: {
 *     purposeTemplateId: // value for 'purposeTemplateId'
 *   },
 * });
 */
export function useDeletePurposeTemplateMutation(options: VueApolloComposable.UseMutationOptions<DeletePurposeTemplateMutation, DeletePurposeTemplateMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePurposeTemplateMutation, DeletePurposeTemplateMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePurposeTemplateMutation, DeletePurposeTemplateMutationVariables>(DeletePurposeTemplateDocument, options);
}
export type DeletePurposeTemplateMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePurposeTemplateMutation, DeletePurposeTemplateMutationVariables>;
